$(document).ready(function(){
	$('#main_carousel').carousel({
		interval: 3000,
	});

	$('#middle_carousel_left').carousel({
		interval: 4000,
	});

	$('#middle_carousel_right').carousel({
		interval: 3000,
	});
});